
import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import ValidationMixin from '@/mixins/ValidationMixin.vue'
import Diagnostic from '@/apis/Diagnostic'

@Component({
  name: 'Diagnostics'
})
export default class Diagnostics extends Mixins(ValidationMixin) {
  email = null
  isValidForm = false
  browser = navigator.userAgent
  width = window.screen.availWidth
  height = window.screen.availHeight

  fetchData() {
    const agent = `{ [email: ${this.email}] [width: ${this.width}] [height: ${this.height}] [browser: ${this.browser}] }`
    Diagnostic.fetchData(this.email)
      .then(({ data }) => {
        if (data.success) {
          const msg = data.msg
          this.sendEmail(agent, msg)
        } else {
          // @ts-ignore
          this.showErrorSystemNotification(data.msg)
        }
      })
      .catch((e) => {
        let string = ''
        e.response.data.trace.forEach(function (item) {
          string = string + '{ [file: ' + item.file + '] '
          string = string + '[line: ' + item.line + '] '
          string = string + '[function: ' + item.function + '] '
          string = string + '[match: ' + item.match + '] '
          string = string + '[class: ' + item.class + '] '
          string = string + '[type: ' + item.type + '] } '
        })
        this.sendEmail(agent, string)
      })
  }

  sendEmail(subject, msg) {
    console.log(subject, msg)
    window.location.replace(`mailto:support@poiskpl.ru?subject=${subject}&body=${msg}`)
  }
}
