import { Http } from '@/apis/Http'

export default {
  fetchData(email) {
    return Http.get('diagnostics/fetch-data', {
      params: {
        email
      }
    })
  },

  writeToLogs(data) {
    return Http.get('diagnostics/write-to-logs', {
      params: {
        data
      }
    })
  }
}
